import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FaTimes, FaExclamationTriangle, FaEdit } from 'react-icons/fa';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    {
        label: 'The Lord of the Rings: The Return of the King',
        year: 2003,
    },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    {
        label: 'The Lord of the Rings: The Fellowship of the Ring',
        year: 2001,
    },
    {
        label: 'Star Wars: Episode V - The Empire Strikes Back',
        year: 1980,
    },
    { label: 'Forrest Gump', year: 1994 },
    { label: 'Inception', year: 2010 },
    {
        label: 'The Lord of the Rings: The Two Towers',
        year: 2002,
    },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: 'Goodfellas', year: 1990 },
    { label: 'The Matrix', year: 1999 },
    { label: 'Seven Samurai', year: 1954 },
    {
        label: 'Star Wars: Episode IV - A New Hope',
        year: 1977,
    },
    { label: 'City of God', year: 2002 },
    { label: 'Se7en', year: 1995 },
    { label: 'The Silence of the Lambs', year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: 'Life Is Beautiful', year: 1997 },
    { label: 'The Usual Suspects', year: 1995 },
    { label: 'Léon: The Professional', year: 1994 },
    { label: 'Spirited Away', year: 2001 },
    { label: 'Saving Private Ryan', year: 1998 },
    { label: 'Once Upon a Time in the West', year: 1968 },
    { label: 'American History X', year: 1998 },
    { label: 'Interstellar', year: 2014 },
    { label: 'Casablanca', year: 1942 },
    { label: 'City Lights', year: 1931 },
    { label: 'Psycho', year: 1960 },
    { label: 'The Green Mile', year: 1999 },
    { label: 'The Intouchables', year: 2011 },
    { label: 'Modern Times', year: 1936 },
    { label: 'Raiders of the Lost Ark', year: 1981 },
    { label: 'Rear Window', year: 1954 },
    { label: 'The Pianist', year: 2002 },
    { label: 'The Departed', year: 2006 },
    { label: 'Terminator 2: Judgment Day', year: 1991 },
    { label: 'Back to the Future', year: 1985 },
    { label: 'Whiplash', year: 2014 },
    { label: 'Gladiator', year: 2000 },
    { label: 'Memento', year: 2000 },
    { label: 'The Prestige', year: 2006 },
    { label: 'The Lion King', year: 1994 },
    { label: 'Apocalypse Now', year: 1979 },
    { label: 'Alien', year: 1979 },
    { label: 'Sunset Boulevard', year: 1950 },
    {
        label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
        year: 1964,
    },
    { label: 'The Great Dictator', year: 1940 },
    { label: 'Cinema Paradiso', year: 1988 },
    { label: 'The Lives of Others', year: 2006 },
    { label: 'Grave of the Fireflies', year: 1988 },
    { label: 'Paths of Glory', year: 1957 },
    { label: 'Django Unchained', year: 2012 },
    { label: 'The Shining', year: 1980 },
    { label: 'WALL·E', year: 2008 },
    { label: 'American Beauty', year: 1999 },
    { label: 'The Dark Knight Rises', year: 2012 },
    { label: 'Princess Mononoke', year: 1997 },
    { label: 'Aliens', year: 1986 },
    { label: 'Oldboy', year: 2003 },
    { label: 'Once Upon a Time in America', year: 1984 },
    { label: 'Witness for the Prosecution', year: 1957 },
    { label: 'Das Boot', year: 1981 },
    { label: 'Citizen Kane', year: 1941 },
    { label: 'North by Northwest', year: 1959 },
    { label: 'Vertigo', year: 1958 },
    {
        label: 'Star Wars: Episode VI - Return of the Jedi',
        year: 1983,
    },
    { label: 'Reservoir Dogs', year: 1992 },
    { label: 'Braveheart', year: 1995 },
    { label: 'M', year: 1931 },
    { label: 'Requiem for a Dream', year: 2000 },
    { label: 'Amélie', year: 2001 },
    { label: 'A Clockwork Orange', year: 1971 },
    { label: 'Like Stars on Earth', year: 2007 },
    { label: 'Taxi Driver', year: 1976 },
    { label: 'Lawrence of Arabia', year: 1962 },
    { label: 'Double Indemnity', year: 1944 },
    {
        label: 'Eternal Sunshine of the Spotless Mind',
        year: 2004,
    },
    { label: 'Amadeus', year: 1984 },
    { label: 'To Kill a Mockingbird', year: 1962 },
    { label: 'Toy Story 3', year: 2010 },
    { label: 'Logan', year: 2017 },
    { label: 'Full Metal Jacket', year: 1987 },
    { label: 'Dangal', year: 2016 },
    { label: 'The Sting', year: 1973 },
    { label: '2001: A Space Odyssey', year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: 'Toy Story', year: 1995 },
    { label: 'Bicycle Thieves', year: 1948 },
    { label: 'The Kid', year: 1921 },
    { label: 'Inglourious Basterds', year: 2009 },
    { label: 'Snatch', year: 2000 },
    { label: '3 Idiots', year: 2009 },
    { label: 'Monty Python and the Holy Grail', year: 1975 },
];

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
];

export default function PaymentForm() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [GstValidated, setGstValidated] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600, cursor: 'default' }}>
                <Box sx={{ height: '4rem', flexDirection: 'row', width: '100%', backgroundColor: 'white', alignItems: 'end' }}>
                    <h1 className='text-end'>
                        <button style={{ fontSize: '1rem', height: '2.8rem', width: '13rem', border: 'none', borderRadius: '0.5rem', backgroundColor: 'rgb(58, 158, 58)', color: 'white', fontWeight: 'bold' }} onClick={handleShow} >Create Payment Form</button>
                    </h1>
                </Box>
                <Table>
                    <TableHead sx={{ backgroundColor: 'lightgray', borderRadius: '0.7rem' }}>

                        <TableRow>

                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 30, fontWeight: 'bold' }}
                            >
                                Created At
                            </TableCell>
                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                From ID
                            </TableCell>
                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                CF From ID
                            </TableCell>
                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Amount
                            </TableCell>
                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                URL
                            </TableCell>

                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 30 }}>sonu Kumer</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120 }}>sonu@123gmail.com</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120 }}>8303138671</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120 }}>15/07/2001</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120 }}>21/09/2021</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120 }}>Hyderabad</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120 }}>23</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>

                </Table>
                {
                    rows.length === 0 && (
                        <center style={{ marginTop: '13rem', marginBottom: '13rem' }} >
                            <Typography variant='h6'>
                                No payment Form Found
                            </Typography>
                            <br />
                            <Typography sx={{ textAlign: 'center' }}>
                                Click Create Payment Form to collect payment from multiple customers using a common payment link. The payment form helps to collect customer details before they make the payment.
                            </Typography>
                            <br />
                            <Typography>
                                Share the payment form on your website, social media platforms, WhatsApp, or any other platform to collect the payment.
                            </Typography>
                            <br />
                            <button style={{ fontSize: '1rem', height: '2.8rem', width: '13rem', border: '1.2px solid rgb(58, 158, 58)', borderRadius: '0.5rem', color: 'rgb(58, 158, 58)', fontWeight: 'bold' }} onClick={handleShow} >Create Payment Form</button>
                        </center>
                    )
                }
            </TableContainer>
            {
                rows.length > 0 && (
                    <TablePagination
                        rowsPerPageOptions={[5, 20, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )
            }
            <br />
            <>
                <Modal style={{ height: '90vh', marginTop: '5rem', overflowY: 'scroll' }} show={show} centered onHide={handleClose} animation={false}>
                    <Modal.Header closeButton style={{ backgroundColor: 'lightgray' }}>
                        <Modal.Title>Create Payment From</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Description"
                                        style={{ padding: '1.2rem' }}
                                        as="textarea" rows={3}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Description
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Ammount</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text id="inputGroupPrepend">INR</InputGroup.Text>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Ammount"
                                            style={{ padding: '1.2rem' }}

                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Ammount
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom05">
                                    <Form.Label>Link Expiry</Form.Label>
                                    <Form.Select style={{ padding: '1.2rem' }}

                                        aria-label="Default select example">
                                        <option value="Customer">12 Hours</option>
                                        <br />
                                        <option value="Customer">1 Day</option>
                                        <br />
                                        <option value="LorryBroker">7 Days</option>
                                        <br />
                                        <option value="LorryWala">14 Days</option>
                                    </Form.Select>
                                </Form.Group>
                                 
                                <Form.Label style={{marginTop:'0.7rem'}}>
                                     <span style={{fontSize:'1rem',color:'gray'}} >Customer Details</span>
                                    <br/>
                                     <span style={{fontSize:'0.7rem',color:'gray'}}>Payment link will be sent to the phone number and email ID</span>
                                </Form.Label>
                                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                    <Form.Label>Email Id</Form.Label>
                                    <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Email Id"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            style={{ padding: '1.2rem' }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Nature Of Business.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        aria-describedby="inputGroupPrepend"
                                        required

                                        style={{ padding: '1.2rem' }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Pan Card Number .
                                    </Form.Control.Feedback>
                                </Form.Group>
                               
                            </Row>
                            <br />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{color:'rgb(58, 158, 58)',fintWeight:'bold'}} onClick={handleClose}>
                            Cancel
                        </Button>
                        <button style={{backgroundColor:'rgb(58, 158, 58)',border:'none',borderRadius:'0.5rem',height:'2.7rem',width:'6rem',color:'white'}} variant="primary" onClick={handleClose}>
                            Create
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        </Paper>
    );
}