import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import CostomerDashboard from './Component/DashBoard/customer_dashboard/CostomerDashboard'
import Login from './Component/Auth/Login/Login_Admin'


const App = () => {

  return (
    <div className='app_style'>
      <BrowserRouter>
        <Routes>
           
          <Route path='/dashboard' element={<CostomerDashboard />} />
          <Route path='/' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App