import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Button, Typography } from '@mui/material';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import Swal from "sweetalert2";
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


export default function Users() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteData(id);
      }
    });
  };

  const deleteData = (id) => {
    axios.post('https://imaapi.emedha.in/member-master/delete', {
      id
    }).then((res) => {
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
      memberDataGet()
    })
  }

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [GstValidated, setGstValidated] = useState(false);

  const handleSubmitGst = (event) => {
    event.preventDefault()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [age, setAge] = useState('')
  const [branch, setBranch] = useState('')
  const [dob, setDob] = useState('')
  const [doj, setDoj] = useState('')
  const [dfc_id, setDfc_id] = useState('')
  const [fss_no, setFss_No] = useState('')
  const [amt, setAmt] = useState("")

  const createMember = () => {
    axios.post('https://imaapi.emedha.in/member-master/create', {
      name,
      email,
      mobile,
      age,
      branch,
      dob,
      doj,
      amt,
      dfc_id,
      fss_no
    }).then((res) => {
      if (res.data.status === 200) {
        createData()
        memberDataGet()
      }
    })
  }


  const [member, setMember] = useState([])

  const memberDataGet = () => {
    axios.get('https://imaapi.emedha.in/member-master/select').then((res) => {
      setMember(res.data.response)
    })
  }

  React.useEffect(() => {
    memberDataGet()
  }, [])

  const createData = () => {
    Swal.fire({
      title: "Data Succefully Added?",
      text: "Your Data Successfully Adedd In database",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Add New Member!",
    }).then((result) => {
      if (result.value) {
        setEmail('')
        setName('')
        setMobile('')
        setAge('')
        setDob('')
        setDoj('')
        setBranch('')
        setShow(true)
      } else {
        setEmail('')
        setName('')
        setMobile('')
        setAge('')
        setDob('')
        setDoj('')
        setBranch('')
        setShow(false)
      }
    });
  }

  const [editAbleUserId, setEditAbleUserId] = useState('')

  const editUser = (id) => {
    axios.post('https://imaapi.emedha.in/member-master/edit/user', {
      id
    }).then((res) => {
      setName(res.data.name)
      setEmail(res.data.email)
      setMobile(res.data.mobile)
      setAge(res.data.age)
      setBranch(res.data.branch)
      setDob(res.data.dob)
      setDoj(res.data.doj)
      setEditAbleUserId(res.data.id)
      setAmt(res.data.amt)
      setDfc_id(res.data.dfc_id)
      setFss_No(res.data.fss_no)
      setShow1(true)
    })
  }

  const editData = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want be able to edit this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit it!",
    }).then((result) => {
      if (result.value) {
        edit(editAbleUserId)
      } else {
        setShow1(false)
      }
    });
  }

  const edit = (id) => {
    axios.post('https://imaapi.emedha.in/member-master/edit', {
      id,
      name,
      email,
      mobile,
      age,
      branch,
      dob,
      doj
    }).then((res) => {
      Swal.fire("Update!", "Your member has been Updated.", "success");
      memberDataGet()
      setShow1(false)
    })
  }

  const searchUser = (value) => {
    axios.post('https://imaapi.emedha.in/member-master/search', {
      value
    }).then((res) => {
      setMember(res.data)
    })
  }

  return (
    <div>
      <Paper>
        <TableContainer sx={{ maxHeight: 620, cursor: 'default' }}>

          <p className='text-start' >
            <input
              style={{ backgroundColor: 'white', height: '2.7rem', borderRadius: '0.6rem', width: '11rem' }}
              placeholder='Search member..'
              onChange={(e) => searchUser(e.target.value)}
            />
          </p>
          <p className='text-end'><button onClick={() => handleShow()} style={{ color: 'white', position: 'relative', top: '-3.7rem', backgroundColor: 'rgb(58, 158, 58)', borderRadius: '0.5rem', width: '8rem', height: '2.6rem', border: 'none' }} ><ControlPointRoundedIcon sx={{ fontWeight: 'bold', fontSize: 20, position: 'relative', top: '-0.1rem' }} />  <span style={{ fontSize: '1.2rem' }} > Add</span></button></p>
          <Table>
            <TableHead sx={{ backgroundColor: 'lightgray' }}>
              <TableRow>
                <TableCell
                  align={"center"}
                  style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                >
                  FSS_NO
                </TableCell>
                <TableCell
                  align={"center"}
                  style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                >
                  Name
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                >
                  Email
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                >
                  Mobile
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 120, fontWeight: 'bold', fontSize: 12 }}
                >
                  Date Of Birth
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 120, fontWeight: 'bold', fontSize: 12 }}
                >
                  Date Of Join
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                >
                  Branch
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 50, fontWeight: 'bold', fontSize: 12 }}
                >
                  Age
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 60, fontWeight: 'bold', fontSize: 12 }}
                >
                  DFC ID
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                >
                  Due Ammount
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 100, fontWeight: 'bold', fontSize: 12 }}
                >
                  Action
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {member
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.FssNo}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.name}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.email}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.mobile}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.dob}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}> {row.doj}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.branch}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 50, fontSize: 12 }}>{row.age}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.DFC_ID}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.dueAmt}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12, flexDirection: 'row', alignItems: 'center' }}>
                        <span onClick={() => deleteUser(row.id)} style={{ cursor: 'pointer' }}>
                          <DeleteForeverIcon style={{ color: 'red' }} />
                        </span>
                        <span style={{ cursor: 'pointer' }} onClick={() => editUser(row.id)}>
                          <BorderColorIcon style={{ marginLeft: 15, color: 'lightblue' }} />
                        </span>
                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
<br/>
        <TablePagination
          rowsPerPageOptions={[5, 20, 50, 100]}
          component="div"
          count={member.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Paper>
      <>
        <Modal style={{ height: '90vh', marginTop: '5rem', overflowY: 'scroll' }} show={show} centered onHide={handleClose} animation={false}>
          <Modal.Header closeButton style={{ backgroundColor: 'lightgray' }}>
            <Modal.Title>Add Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
              <Row className="mb-3">
                <Form.Group as={Col} md="11" controlId="validationCustom02">
                  <Form.Label>Full Name</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="text"
                      placeholder="Please Enter Full Name"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Full Name
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>FSS_NO</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="text"
                      placeholder="FSS NO."
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setFss_No(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid ID
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Due Ammount</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="text"
                      placeholder="Due Ammount"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setAmt(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Ammount
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: '0.7rem' }}>
                  <span style={{ fontSize: '1rem', color: 'gray' }} >Member Details</span>
                  <br />
                  <span style={{ fontSize: '0.7rem', color: 'gray' }}>Create your profile information for the more instructions</span>
                </Form.Label>
                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                  <Form.Label>Email Id</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control

                      type="text"
                      placeholder="Email Id"
                      aria-describedby="inputGroupPrepend"
                      required
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Nature Of Business.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control

                    type="text"
                    placeholder="Enater Mobile Number"
                    aria-describedby="inputGroupPrepend"
                    required
                    onChange={(e) => setMobile(e.target.value)}
                    style={{ padding: '0.8rem' }}

                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Pan Card Number .
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>DOB</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="date"
                      placeholder="Date Of Birth"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Date Of Birth
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Date Of Join</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="date"
                      placeholder="Date Of Join"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setDoj(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Date Of Join
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="10" controlId="validationCustom02">
                  <Form.Label>Branch</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="text"
                      placeholder="Enter Your branch"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setBranch(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Your branch
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Age</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="text"
                      placeholder="Age"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter your age
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>DFC ID</Form.Label>
                  <InputGroup>
                    <Form.Control

                      required
                      type="text"
                      placeholder="DFC ID"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setDfc_id(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Full Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ color: 'rgb(58, 158, 58)', fintWeight: 'bold' }} onClick={handleClose}>
              Cancel
            </Button>
            <button style={{ backgroundColor: 'rgb(58, 158, 58)', border: 'none', borderRadius: '0.5rem', height: '2.7rem', width: '6rem', color: 'white' }} variant="primary" onClick={() => createMember()}>
              Create
            </button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal style={{ height: '90vh', marginTop: '5rem', overflowY: 'scroll' }} show={show1} centered onHide={() => setShow1(false)} animation={false}>
          <Modal.Header closeButton style={{ backgroundColor: 'lightgray' }}>
            <Modal.Title>Update Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
              <Row className="mb-3">
                <Form.Group as={Col} md="11" controlId="validationCustom02">
                  <Form.Label>Full Name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={name}
                      required
                      type="text"
                      placeholder="Please Enter Full Name"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Full Name
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>FSS_NO</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={fss_no}
                      required
                      type="text"
                      placeholder="FSS NO."
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setFss_No(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid ID
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Due Ammount</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={amt}
                      required
                      type="text"
                      placeholder="Due Ammount"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setAmt(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Ammount
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: '0.7rem' }}>
                  <span style={{ fontSize: '1rem', color: 'gray' }} >Member Details</span>
                  <br />
                  <span style={{ fontSize: '0.7rem', color: 'gray' }}>Create your profile information for the more instructions</span>
                </Form.Label>
                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                  <Form.Label>Email Id</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                      value={email}
                      type="text"
                      placeholder="Email Id"
                      aria-describedby="inputGroupPrepend"
                      required
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Nature Of Business.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    value={mobile}
                    type="text"
                    placeholder="Enater Mobile Number"
                    aria-describedby="inputGroupPrepend"
                    required
                    onChange={(e) => setMobile(e.target.value)}
                    style={{ padding: '0.8rem' }}

                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Pan Card Number .
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>DOB</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={dob}
                      required
                      type="date"
                      placeholder="Date Of Birth"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Date Of Birth
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Date Of Join</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={doj}
                      required
                      type="date"
                      placeholder="Date Of Join"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setDoj(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Date Of Join
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="10" controlId="validationCustom02">
                  <Form.Label>Branch</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={branch}
                      required
                      type="text"
                      placeholder="Enter Your branch"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setBranch(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Your branch
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ height: '0.7rem' }} ></div>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Age</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={age}
                      required
                      type="text"
                      placeholder="Age"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter your age
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>DFC ID</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={dfc_id}
                      required
                      type="text"
                      placeholder="DFC ID"
                      style={{ padding: '0.8rem' }}
                      onChange={(e) => setDfc_id(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Full Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ color: 'rgb(58, 158, 58)', fintWeight: 'bold' }} onClick={() => setShow1(false)}>
              Cancel
            </Button>
            <button style={{ backgroundColor: 'rgb(58, 158, 58)', border: 'none', borderRadius: '0.5rem', height: '2.7rem', width: '6rem', color: 'white' }} variant="primary" onClick={() => editData()}>
              Update
            </button>
          </Modal.Footer>
        </Modal>

      </>
    </div>
  );
}