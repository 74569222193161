import React, { useState } from 'react'
import imaImg from '../../images/ima.jpeg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";

const Login_Admin = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const admin_login = () => {
        axios.post('https://imaapi.emedha.in/login_admin', {
            email,
            password
        }).then((res) => {
            if (res.data.status === 200) {
                loginSuccess()
            } else {
                Swal.fire("Invalid Credential..!", "Please Enter valid credential...!", "error");
            }
        })
    }

    
    function refreshPage() {
        window.location.reload(false);
    }

    const loginSuccess = () => {
        Swal.fire({
            title: "Login Successful",
            text: "You want to go to the dashboard....!",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                navigate('/dashboard')
                sessionStorage.setItem('key', 'admin_mzsbdags273729dnckkyeridsjcbdagdhaf')
                refreshPage()
            } else {
                navigate('/')
            }
        });
    }

    const validPop = (id) => {
        Swal.fire({
            title: "Credential Empty!",
            text: "Please Enter valid credential...!",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Try again!",
        }).then((result) => {
            if (result.value) {
                navigate('/')
            }
        });
    };

    const loginValidation = () => {
        if (email === '' && password === '') {
            validPop()
        } else {
            admin_login()
        }
    }

    return (
        <div style={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }} className='loginMainContainer'>
            <div className='loginContainer col-xxl-4 mx-auto' style={{ height: '25rem', backgroundColor: 'white', borderRadius: '0.5rem' }}>
                <center>
                    <img style={{ position: 'relative', top: '0.5rem' }} src={imaImg} width='70px' />
                </center>
                <h2 className='text-center text-success'>Login</h2>
                <div className='pt-5 ps-4 pe-4' >
                    <input onChange={(e) => setEmail(e.target.value)} placeholder='User Email' className='mb-4 ps-2' style={{ height: '3rem', border: '1px solid lightgray', borderRadius: '0.5rem', outline: 'none', outlineColor: 'lightgray', width: "100%" }} />
                    <input type='password' onChange={(e) => setPassword(e.target.value)} placeholder='Password' className='mb-5 ps-2' style={{ height: '3rem', border: '1px solid lightgray', borderRadius: '0.5rem', outline: 'none', outlineColor: 'lightgray', width: "100%" }} />
                    <button style={{ backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '0.5rem', height: '2.9rem', width: '8rem' }} onClick={() => loginValidation()} >Login</button>
                </div>
            </div>
        </div>
    )
}

export default Login_Admin