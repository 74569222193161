import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaDesktop, FaEdit, FaEnvelope, FaHandPeace, FaLink, FaPencilAlt, FaReceipt, FaUsers, FaWeebly } from 'react-icons/fa';
import { useAppStore } from '../AppStore/AppStore';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useEffect } from 'react'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import PaymentForm from '../PaymentForm/PaymentForm';
import Payment from '../Payment/Payment';
import Members from '../CustomerDashboardHomePage/Members'
import Webhook from '../WebHook/WebHook';
import RecieptDetails from '../RecieptDetails/RecieptDetails';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const drawerWidth = 240;



const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function Sidebar() {

    const [members, setMembers] = useState(true)
    const [isPaymentForm, setIsPaymentForm] = useState(false)
    const [isPaymentLink, setIsPaymentLink] = useState(false)
    const [isWebHook, setIsWebHook] = useState(false)
    const [isRecieptDetails, setIsRecieptDetails] = useState(false)


    useEffect(() => {

        setTimeout(() => {

        })
    }, [])

    const theme = useTheme();
    const [dopen, setOpen] = React.useState(true);

    const open = useAppStore((state) => state.dopen)

    const handleDrawerClose = () => {
        setOpen(!open);
    };

    const classes = useStyles();



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer style={{ background: 'red', backgroundColor: 'red', paddingLeft: 10 }} elevation={2} variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem onClick={() => {
                        setIsPaymentLink(false)
                        setIsPaymentForm(false)
                        setMembers(true)
                        setIsWebHook(false)
                        setIsRecieptDetails(false)
                    }} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaUsers fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Members" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        setIsPaymentLink(true)
                        setIsPaymentForm(false)
                        setMembers(false)
                        setIsWebHook(false)
                        setIsRecieptDetails(false)
                    }} disablePadding sx={{ display: 'block' }}  >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaLink fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Payment Links" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        setIsPaymentLink(false)
                        setIsPaymentForm(true)
                        setMembers(false)
                        setIsWebHook(false)
                        setIsRecieptDetails(false)
                    }} disablePadding sx={{ display: 'block' }}  >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaEdit fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Payments Forms" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        setIsPaymentLink(false)
                        setIsPaymentForm(false)
                        setMembers(false)
                        setIsWebHook(true)
                        setIsRecieptDetails(false)
                    }} disablePadding sx={{ display: 'block' }}  >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaEnvelope fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Message Status" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        setIsPaymentLink(false)
                        setIsPaymentForm(false)
                        setMembers(false)
                        setIsWebHook(false)
                        setIsRecieptDetails(true)
                    }} disablePadding sx={{ display: 'block' }}  >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaReceipt fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Reciept Details" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
            </Drawer>
            <Box className='boxbg mt-5 w-100' sx={{ overflow: 'scroll' }}>
                <Box className=' p-3 '>
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Card elevation={0} className={classes.root}>
                                        <CardActionArea>
                                            {
                                                isPaymentForm && (
                                                    <PaymentForm></PaymentForm>
                                                )
                                            }
                                            {
                                                isPaymentLink && (
                                                    <Payment></Payment>
                                                )
                                            }
                                            {
                                                members && (
                                                    <Members />
                                                )
                                            }
                                            {
                                                isWebHook && (
                                                    <Webhook />
                                                )
                                            }
                                            {
                                                isRecieptDetails && (
                                                    <RecieptDetails/>
                                                )
                                            }
                                        </CardActionArea>
                                    </Card>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Box>
        </Box>
    );
}
