import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Button, Typography } from '@mui/material';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import Swal from "sweetalert2";
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


export default function RecieptDetails() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteData(id);
            }
        });
    };

    const deleteData = (id) => {
        axios.post('https://imaapi.emedha.in/member-master/reciept-details/delete/v-chat', {
            id
        }).then((res) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            memberDataGet()
        })
    }

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [GstValidated, setGstValidated] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [FssNo, setFssNo] = useState('')
    const [reciept_link, set_reciept_link] = useState('')

    const createMember = () => {

        const formData = new FormData()

        formData.append('name', name)
        formData.append('mobile', mobile)
        formData.append('FssNo', FssNo)
        formData.append('pdf', reciept_link)

        axios.post('https://imaapi.emedha.in/member-master/reciept-details/v-chat', formData).then((res) => {
            if (res.data.statusCode === 200) {
                createData()
                memberDataGet()
            }
        })
    }


    const [member, setMember] = useState([])

    const memberDataGet = () => {
        axios.get('https://imaapi.emedha.in/member-master/reciept-details/data/v-chat').then((res) => {
            setMember(res.data.response)
        })
    }

    React.useEffect(() => {
        memberDataGet()
    }, [])

    const createData = () => {
        Swal.fire({
            title: "Data Succefully Added?",
            text: "Your Data Successfully Adedd In database",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Add New Member!",
        }).then((result) => {
            if (result.value) {

                setName('')
                setMobile('')
                set_reciept_link('')
                setFssNo('')

            } else {

                setName('')
                setMobile('')
                set_reciept_link('')
                setFssNo('')

            }
        });
    }

    const [editAbleUserId, setEditAbleUserId] = useState('')

    const editUser = (id) => {
        axios.post('https://imaapi.emedha.in/member-master/reciept-details/get-data/v-chat', {
            id
        }).then((res) => {
            setName(res.data.name)
            setMobile(res.data.mobile)
            set_reciept_link(res.data.receipt_link)
            setFssNo(res.data.fss_no)
            setShow1(true)
            setEditAbleUserId(id)
        })
    }

    const editData = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want be able to edit this user!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, edit it!",
        }).then((result) => {
            if (result.value) {
                edit(editAbleUserId)
            } else {
                setShow1(false)
            }
        });
    }

    const edit = (id) => {

        const formData = new FormData()

        formData.append('name', name)
        formData.append('mobile', mobile)
        formData.append('FssNo', FssNo)
        formData.append('pdf', reciept_link)
        formData.append('id', id)


        axios.post('https://imaapi.emedha.in/member-master/reciept-details/edit-data/v-chat',  formData).then((res) => {
            Swal.fire("Update!", "Your member has been Updated.", "success");
            memberDataGet()
            setShow1(false)
        })
    }

    const searchUser = (value) => {
        axios.post('https://imaapi.emedha.in/member-master/reciept-details/search/v-chat', {
            value
        }).then((res) => {
            setMember(res.data)
        })
    }

    return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 620, cursor: 'default' }}>

                    <p className='text-start' >
                        <input
                            style={{ backgroundColor: 'white', height: '2.7rem', borderRadius: '0.6rem', width: '11rem' }}
                            placeholder='Search member..'
                            onChange={(e) => searchUser(e.target.value)}
                        />
                    </p>
                    <p className='text-end'><button onClick={() => handleShow()} style={{ color: 'white', position: 'relative', top: '-3.7rem', backgroundColor: 'rgb(58, 158, 58)', borderRadius: '0.5rem', width: '8rem', height: '2.6rem', border: 'none' }} ><ControlPointRoundedIcon sx={{ fontWeight: 'bold', fontSize: 20, position: 'relative', top: '-0.1rem' }} />  <span style={{ fontSize: '1.2rem' }} > Add</span></button></p>
                    <Table>
                        <TableHead sx={{ backgroundColor: 'lightgray' }}>
                            <TableRow>
                                <TableCell
                                    align={"center"}
                                    style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    FSS_NO
                                </TableCell>
                                <TableCell
                                    align={"center"}
                                    style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    Name
                                </TableCell>
                                <TableCell

                                    align={"center"}
                                    style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    Reciept Link
                                </TableCell>
                                <TableCell

                                    align={"center"}
                                    style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    Mobile
                                </TableCell>
                                <TableCell

                                    align={"center"}
                                    style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {member
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell align={"center"}
                                                style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.Fss_No}</TableCell>
                                            <TableCell align={"center"}
                                                style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.Name}</TableCell>
                                            <TableCell align={"center"}
                                                style={{ top: 57, minWidth: 87, fontSize: 12 }}>
                                                    <a href={row.Receipt_link} >Show Pdf</a>
                                                </TableCell>
                                            <TableCell align={"center"}
                                                style={{ top: 57, minWidth: 87, fontSize: 12 }}>{row.Mobile}</TableCell>
                                            <TableCell align={"center"}
                                                style={{ top: 57, minWidth: 87, fontSize: 12, flexDirection: 'row', alignItems: 'center' }}>
                                                <span onClick={() => deleteUser(row.id)} style={{ cursor: 'pointer' }}>
                                                    <DeleteForeverIcon style={{ color: 'red' }} />
                                                </span>
                                                <span style={{ cursor: 'pointer' }} onClick={() => editUser(row.id)}>
                                                    <BorderColorIcon style={{ marginLeft: 15, color: 'lightblue' }} />
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <TablePagination
                    rowsPerPageOptions={[5, 20, 50, 100]}
                    component="div"
                    count={member.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Paper>
            <>
                <Modal style={{ height: '100vh', marginTop: '5rem', overflowY: 'scroll' }} show={show} centered onHide={handleClose} animation={false}>
                    <Modal.Header closeButton style={{ backgroundColor: 'lightgray' }}>
                        <Modal.Title>Add Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
                            <Row className="mb-3">
                                <Form.Group as={Col} md="5" controlId="validationCustom02">
                                    <Form.Label>Fss No.</Form.Label>
                                    <InputGroup>
                                        <Form.Control

                                            required
                                            type="text"
                                            placeholder="Fss No."
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => setFssNo(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Fss No.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="7" controlId="validationCustom02">
                                    <Form.Label>Name</Form.Label>
                                    <InputGroup>
                                        <Form.Control

                                            required
                                            type="text"
                                            placeholder="Full Name"
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter valid name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div style={{ height: '0.7rem' }} ></div>
                                <Form.Group as={Col} md="7" controlId="validationCustom02">
                                    <Form.Label>Add Pdf</Form.Label>
                                    <InputGroup>
                                        <Form.Control

                                            required
                                            type="file"
                                            placeholder="pdf"
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => set_reciept_link(e.target.files[0])}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid pdf image
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Mobile</Form.Label>
                                    <InputGroup>
                                        <Form.Control

                                            required
                                            type="text"
                                            placeholder="Mobile Number"
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter mobile number
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <br />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ color: 'rgb(58, 158, 58)', fintWeight: 'bold' }} onClick={handleClose}>
                            Cancel
                        </Button>
                        <button type='submit' style={{ backgroundColor: 'rgb(58, 158, 58)', border: 'none', borderRadius: '0.5rem', height: '2.7rem', width: '6rem', color: 'white' }} variant="primary" onClick={() => createMember()}>
                            Create
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
            <>
                <Modal style={{ height: '90vh', marginTop: '5rem', overflowY: 'scroll' }} show={show1} centered onHide={() => setShow1(false)} animation={false}>
                    <Modal.Header closeButton style={{ backgroundColor: 'lightgray' }}>
                        <Modal.Title>Update Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
                            <Row className="mb-3">
                                <Form.Group as={Col} md="10" controlId="validationCustom02">
                                    <Form.Label>Full Name</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            value={name}
                                            required
                                            type="text"
                                            placeholder="Please Enter Full Name"
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Full Name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div style={{ height: '0.7rem' }} ></div>
                                <Form.Group as={Col} md="5" controlId="validationCustom02">
                                    <Form.Label>FSS_NO</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            value={FssNo}
                                            required
                                            type="text"
                                            placeholder="FSS NO."
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => setFssNo(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid ID
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Mobile</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            value={mobile}
                                            required
                                            type="text"
                                            placeholder="Enter Mobile Number"
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Ammount
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="7" controlId="validationCustomUsername">
                                    <Form.Label>Reciept Link</Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        <Form.Control
                                            type="file"
                                            placeholder="Reciept Link"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => set_reciept_link(e.target.files[0])}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Select valid pdf image.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <div style={{ height: '0.7rem' }} ></div>
                            </Row>
                            <br />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ color: 'rgb(58, 158, 58)', fintWeight: 'bold' }} onClick={() => setShow1(false)}>
                            Cancel
                        </Button>
                        <button style={{ backgroundColor: 'rgb(58, 158, 58)', border: 'none', borderRadius: '0.5rem', height: '2.7rem', width: '6rem', color: 'white' }} variant="primary" onClick={() => editData()}>
                            Update
                        </button>
                    </Modal.Footer>
                </Modal>

            </>
        </div>
    );
}