import React from 'react'
import Navbar from './CostumerNav/CustomerNav'
import Sidebar from './Sidebar/Sidebar'

const CostomerDashboard = () => {
  return (
    <div className='app-style'>
      <Navbar/>
      <Sidebar/>
    </div>
  )
}

export default CostomerDashboard