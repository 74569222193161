import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import axios from 'axios';
import { FaCaretDown, FaFilter, FaItunes } from 'react-icons/fa';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack';
import SimpleBackdrop from '../../../Backdrop/Backdrop';
import moment from 'moment'
import { Typography } from '@mui/material';



export default function Webhook() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(9);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const [webhook, setWebhook] = useState([])

    const [isBackDrop, setIsBackdrop] = useState(false)


    const memberDataGet = () => {
        setIsBackdrop(true)
        axios.post('https://imaapi.emedha.in/member-master/webhook').then((res) => {
            setWebhook(res.data)
            setIsBackdrop(false)
        })
    }

    const [date, setDate] = useState('')
    const [wait, setWait] = useState(false)
    const [filterData, setFilterData] = useState([])
    const [deliwer, setDeliwer] = useState()
    const [sent, setSent] = useState()
    const [failed, setFailed] = useState()

    let totalMsg = deliwer + sent + failed

    const filterWebhook = () => {
        setWait(true)
        axios.post('https://imaapi.emedha.in/member-master/filter-by-date', {
            date
        }).then((res) => {
            setFilterData(res.data.allData)
            setDeliwer(res.data.res1)
            setSent(res.data.res2)
            setFailed(res.data.res3)
            setWait(false)
            setOpen(false)
            setOpen1(true)
        })
    }

    React.useEffect(() => {
        memberDataGet()
    }, [])


    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const [open1, setOpen1] = React.useState(false);



    const handleClose1 = () => {
        setOpen1(false);
    };

    let index = ''


    return (
        <div>
            <Paper>
                <Stack direction="row" spacing={2}>
                    <div>
                        <div className='card' style={{ width: '100%', alignItems: 'start', border: 'none', boxShadow: 'none', marginBottom: 10 }}><button ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle} style={{ height: 35, backgroundColor: 'rgb(58, 158, 58)', borderRadius: 3, color: 'white', fontWeight: 'bold', border: 'none' }} >Filter by date <FaCaretDown size={23} /> </button></div>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper style={{ marginTop: 10 }}>
                                        <ClickAwayListener onClickAway={handleClose}>

                                            <div className='card' style={{ height: 270, width: 510, cursor: 'auto', border: 'none', position: 'relative', padding: 10 }} >
                                                <h5 style={{ textAlign: 'start', marginTop: 10 }} >Select date to the filter</h5>
                                                <input onChange={(e) => setDate(e.target.value)} type='date' style={{ outline: 'none', border: 'none', borderBottom: '3px solid rgb(58, 158, 58)', marginTop: 35 }} />
                                                {
                                                    !wait && (
                                                        <button onClick={() => filterWebhook()} style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(58, 158, 58)', position: 'absolute', right: 10, bottom: 10, border: 'none', borderRadius: '0.2rem', width: '5rem', height: 35 }} >Filter</button>
                                                    )

                                                }
                                                {
                                                    wait && (
                                                        <button disabled={true} onClick={() => filterWebhook()} style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(58, 158, 58)', position: 'absolute', right: 10, bottom: 10, border: 'none', borderRadius: '0.2rem', width: '7rem', height: 35 }} >Filtering..</button>
                                                    )
                                                }
                                                <button onClick={() => setOpen(false)} style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'red', position: 'absolute', right: 130, bottom: 10, border: 'none', borderRadius: '0.2rem', width: '5rem', height: 35 }} >Cancel</button>
                                            </div>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </Stack>
                <TableContainer sx={{ maxHeight: 620, cursor: 'default' }}>
                    <Table>
                        <TableHead sx={{ backgroundColor: 'lightgray' }}>
                            <TableRow>
                                <TableCell
                                    align={"start"}
                                    style={{ top: 57, minWidth: 260, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    Mobile Number
                                </TableCell>
                                <TableCell
                                    align={"start"}
                                    style={{ top: 57, minWidth: 530, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    WID
                                </TableCell>
                                <TableCell
                                    align={"start"}
                                    style={{ top: 57, minWidth: 200, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    Date
                                </TableCell>
                                <TableCell

                                    align={"start"}
                                    style={{ top: 57, minWidth: 140, fontWeight: 'bold', fontSize: 12 }}
                                >
                                    Message Status
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {webhook
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {

                                    index = row.index + 1

                                    let color = ''

                                    if (row.status === 'read') {
                                        color = '#1d98eb'
                                    }

                                    if (row.status === 'sent') {
                                        color = 'blue'
                                    }

                                    if (row.status === 'delivered') {
                                        color = 'green'
                                    }

                                    if (row.status === 'failed') {
                                        color = 'red'
                                    }


                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell align={"start"}
                                                style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.customer_mobile}</TableCell>
                                            <TableCell align={"start"}
                                                style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.wid}</TableCell>
                                            <TableCell align={"start"}
                                                style={{ top: 57, minWidth: 30, fontSize: 12 }}>{`${moment(row.msg_date).date()}/${moment(row.msg_date).month() + 1}/${moment(row.msg_date).year()}`}</TableCell>
                                            <TableCell align={"start"}
                                                style={{ top: 57, minWidth: 87, fontSize: 12 }}>
                                                <button style={{ height: 20, color: 'white', fontWeight: 'bold', borderRadius: '0.4rem', border: 'none', paddingLeft: 10, paddingRight: 10, backgroundColor: color }} >{row.status}</button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <TablePagination
                    rowsPerPageOptions={[5, 20, 50, 100]}
                    component="div"
                    count={webhook.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <div>

                <Dialog
                    open={open1}
                    onClose={handleClose1}
                    maxWidth='lg'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Date: ${moment(date).date()}/${moment(date).month() + 1}/${moment(date).year()}`}
                        <Box style={{display:'flex'}}>
                            <Typography style={{ marginTop: '0.4rem', border:'1px solid gray', padding:10,borderRadius:'0.1rem', }}>Total Messages: {totalMsg}</Typography>
                            <Typography style={{ marginTop: '0.4rem', marginLeft:22, border:'1px solid gray', padding:10,borderRadius:'0.1rem' }}>Total Delivered Messages: {deliwer}</Typography>
                            <Typography style={{ marginTop: '0.4rem', marginLeft:22, border:'1px solid gray', padding:10,borderRadius:'0.1rem' }}>Total Sent Messages: {sent}</Typography>
                            <Typography style={{ marginTop: '0.4rem', marginLeft:22, border:'1px solid gray', padding:10,borderRadius:'0.1rem' }}>Total Failed Messages: {failed}</Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ width: 1000, height: 480 }} >
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Sent Message" value="1" />
                                            <Tab label="Delivered Message" value="2" />
                                            <Tab label="Failed Message" value="3" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <TableContainer sx={{ maxHeight: 400, cursor: 'default' }}>
                                            <Table>
                                                <TableHead sx={{ backgroundColor: 'lightgray' }}>
                                                    <TableRow>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 260, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            Mobile Number
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 520, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            WID
                                                        </TableCell>

                                                        <TableCell

                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 100, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            Message Status
                                                        </TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filterData

                                                        .map((row) => {

                                                            let color = ''

                                                            if (row.status !== 'sent') {
                                                                return
                                                            }

                                                            if (row.status === 'read') {
                                                                color = '#1d98eb'
                                                            }

                                                            if (row.status === 'sent') {
                                                                color = 'blue'
                                                            }

                                                            if (row.status === 'delivered') {
                                                                color = 'green'
                                                            }

                                                            if (row.status === 'failed') {
                                                                color = 'red'
                                                            }

                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.customer_mobile}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.wid}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>
                                                                        <button style={{ height: 20, color: 'white', fontWeight: 'bold', borderRadius: '0.4rem', border: 'none', paddingLeft: 10, paddingRight: 10, backgroundColor: color }} >{row.status}</button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                            {
                                                filterData.length === 0 && (
                                                    <div className='card' style={{ width: '100%', height: '40vh', alignItems: 'center', justifyContent: 'center', border: 'none' }} >
                                                        <img src='https://img.freepik.com/free-vector/image-upload-concept-landing-page_23-2148310441.jpg?size=626&ext=jpg&ga=GA1.1.1809797267.1663748799&semt=ais' style={{ width: 120 }} />
                                                        <h6>0 Message available</h6>
                                                    </div>
                                                )
                                            }
                                        </TableContainer>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <TableContainer sx={{ maxHeight: 400, cursor: 'default' }}>
                                            <Table>
                                                <TableHead sx={{ backgroundColor: 'lightgray' }}>
                                                    <TableRow>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 260, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            Mobile Number
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 520, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            WID
                                                        </TableCell>
                                                        <TableCell

                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 100, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            Message Status
                                                        </TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filterData

                                                        .map((row) => {

                                                            let color = ''

                                                            if (row.status !== 'delivered') {
                                                                return
                                                            }

                                                            if (row.status === 'read') {
                                                                color = '#1d98eb'
                                                            }

                                                            if (row.status === 'sent') {
                                                                color = 'blue'
                                                            }

                                                            if (row.status === 'delivered') {
                                                                color = 'green'
                                                            }

                                                            if (row.status === 'failed') {
                                                                color = 'red'
                                                            }

                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.customer_mobile}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.wid}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>
                                                                        <button style={{ height: 20, color: 'white', fontWeight: 'bold', borderRadius: '0.4rem', border: 'none', paddingLeft: 10, paddingRight: 10, backgroundColor: color }} >{row.status}</button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                            {
                                                filterData.length === 0 && (
                                                    <div className='card' style={{ width: '100%', height: '40vh', alignItems: 'center', justifyContent: 'center', border: 'none' }} >
                                                        <img src='https://img.freepik.com/free-vector/image-upload-concept-landing-page_23-2148310441.jpg?size=626&ext=jpg&ga=GA1.1.1809797267.1663748799&semt=ais' style={{ width: 120 }} />
                                                        <h6>0 Message available</h6>
                                                    </div>
                                                )
                                            }
                                        </TableContainer>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <TableContainer sx={{ maxHeight: 400, cursor: 'default' }}>
                                            <Table>
                                                <TableHead sx={{ backgroundColor: 'lightgray' }}>
                                                    <TableRow>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 260, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            Mobile Number
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 520, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            WID
                                                        </TableCell>
                                                        <TableCell

                                                            align={"start"}
                                                            style={{ top: 57, minWidth: 100, fontWeight: 'bold', fontSize: 12 }}
                                                        >
                                                            Message Status
                                                        </TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filterData

                                                        .map((row) => {

                                                            let color = ''

                                                            if (row.status !== 'failed') {
                                                                return
                                                            }

                                                            if (row.status === 'read') {
                                                                color = '#1d98eb'
                                                            }

                                                            if (row.status === 'sent') {
                                                                color = 'blue'
                                                            }

                                                            if (row.status === 'delivered') {
                                                                color = 'green'
                                                            }

                                                            if (row.status === 'failed') {
                                                                color = 'red'
                                                            }

                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.customer_mobile}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.wid}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, minWidth: 87, fontSize: 12 }}>
                                                                        <button style={{ height: 20, color: 'white', fontWeight: 'bold', borderRadius: '0.4rem', border: 'none', paddingLeft: 10, paddingRight: 10, backgroundColor: color }} >{row.status}</button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                            {
                                                filterData.length === 0 && (
                                                    <div className='card' style={{ width: '100%', height: '40vh', alignItems: 'center', justifyContent: 'center', border: 'none' }} >
                                                        <img src='https://img.freepik.com/free-vector/image-upload-concept-landing-page_23-2148310441.jpg?size=626&ext=jpg&ga=GA1.1.1809797267.1663748799&semt=ais' style={{ width: 120 }} />
                                                        <h6>0 Message available</h6>
                                                    </div>
                                                )
                                            }
                                        </TableContainer>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose1} autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            {
                isBackDrop && (
                    <SimpleBackdrop />
                )
            }
        </div>
    );
}